define("workflows-web/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "$": "$",
    "ACCOUNT_NOT_FOUND": "It looks like you haven't got a Workflows account just yet. You can simply sign up for a demo by clicking on this",
    "INVALID_TOKEN": "Login Failed",
    "USER_ACCOUNT_NOT_FOUND": "Access to workflows is currently unavailable for you. To obtain access, kindly reach out to your system administrator. ",
    "activate_user": "Activate a User",
    "active": "Active",
    "active-workflows-reached-{limit}": "You have reached the permissible limit of {limit} active workflows. Disable one of the active workflows and refresh the browser to enable this workflow.",
    "add": "Add",
    "add-action": "Add Action",
    "add-fields": "Add Fields",
    "add-filter": "Add Filter",
    "add-more-actions": "Add more actions",
    "add_activity": "Add Activity",
    "add_attachments_to_issue": "Add attachments To An Issue",
    "add_case_feed_update": "Add Case Feed Update",
    "add_comment": "Add Comment",
    "add_computer_to_computer_group": "Add Computer to Computer Group",
    "add_contact_to_groups": "Add Contact To Groups",
    "add_deal": "Add Deal",
    "add_internal_note": "Add Internal Note",
    "add_member_to_group": "Add Member to Group",
    "add_organization": "Add Organization",
    "add_person": "Add Person",
    "add_private_note": "Add Private Note",
    "add_reply": "Add Reply",
    "add_reply_with_canned_action": "Add Reply With Canned Action",
    "add_subscribers": "Add Subscribers",
    "add_time_off_request": "Create a new Time Off request",
    "add_user_to_group": "Add a User to a Group",
    "add_user_to_user_group": "Add User to User Group",
    "additional-fields": "Additional Fields",
    "agents": {
      "no-agents-content": "No Active Agents found",
      "no-agents-header": "No Active Agents",
      "no-deactivated-agents-content": "No Deactivated Agents Found",
      "no-deactivated-agents-header": "No Deactivated Agents",
      "no-pending-agents-content": "No Pending Agents Found",
      "no-pending-agents-header": "No Pending Agents",
      "title": "Agents",
      "title-caps": "AGENTS"
    },
    "ai-action-text": "AI",
    "all": "All",
    "all-time": "All Time",
    "and": "and",
    "app-description": {
      "asana": {
        "basic-info": "Asana is the easiest way to manage team projects and your individual tasks.",
        "unlink-failure-generic-message": "Something went wrong while trying to unlink Asana. Please try again after sometime."
      },
      "azure-active-directory": {
        "basic-info": "Azure Active Directory is Microsoft's multi-tenant, cloud-based directory and identity management service. For an organization, Azure Active Directory is an enterprise identity service which provides single sign-on and multi-factor authentication to help protect your users from cyber security attacks.",
        "how-to-setup": {
          "allow-permissions": "2. Allow all requested permissions in the Azure Active Directory login consent screen to let Workflows list and read your Azure Active Directory Tenants.",
          "authorize-azure-active-directory": "1. Enter Tenant ID and click \"Save\" to link your Azure Active Directory Tenant.",
          "choose-tenant-workflow-action": "3. You can now choose the linked tenant in workflow actions to perform supported operations on your Azure Active Directory Tenants.",
          "unlink-tenant": "4. To unlink a tenant from Workflows, choose \"Unlink\" for the desired tenant. This tenant will then be no longer accessible for workflow actions."
        }
      },
      "bamboohr": {
        "basic-info": "Bamboo HR is an HR and Recruitment software which helps you to manage your workforce.",
        "how-to-setup": {
          "add-to-workflows": "4. In HappyFox Workflows, navigate back to Bamboo HR app's manage screen and enter the API Key copied in the \"API Token\" section. Add your \"Sub domain\" and a password of your own.",
          "click-on-profile": "1. Login to your Bamboo HR Account and click on your profile icon on the top right corner of the screen.",
          "create-new-key": "3. Create a new API Key and copy it to your clipboard.",
          "go-to-api-keys": "2. Click on API Keys option from the dropdown displayed.",
          "save-and-activate": "5. Save and activate the integration"
        }
      },
      "google-sheets": {
        "basic-info": "Use the Google Sheets integration to read and write data in linked spreadsheets.",
        "how-to-setup": {
          "allow-google-sheets-permissions": "2. Allow all requested permissions in the Google login consent screen to let Workflows list and read your Google Sheets.",
          "authorize-google-sheets": "1. Click \"Authorize Google Sheets\" to link your Google Account.",
          "choose-account-workflow-action": "3. You can now choose the linked account in workflow actions to perform supported operations on your Google Sheets.",
          "unlink-option": "4. To unlink a Google account from Workflows, choose \"Unlink\" for the desired account. This account and its Google Sheets will then be no longer accessible for workflow actions."
        }
      },
      "happyfox": {
        "basic-info": "HappyFox is an all-in-one help desk ticketing system that provides faster and better support for your customers. This integration allows you to automate your business processes seamlessly."
      },
      "happyfox-chatbot": {
        "basic-info": "HappyFox Chatbot is a software program that engages with users automatically."
      },
      "happyfox-service-desk": {
        "basic-info": "HappyFox Service Desk is a robust system that enables you to deliver employee support and achieve ITSM goals. Using HappyFox Service Desk, you can resolve incidents, fulfil service requests, solve problems, track changes, manage releases, assets and more! By connecting HappyFox Workflows with Service Desk, you can automate actions and business processes that occur inside Service Desk."
      },
      "jamf-pro": {
        "basic-info": "JAMF Pro provides an industry leading Apple Enterprise Device management solution. Jamf Pro delivers everything IT needs to maximize their time and enable end users to be more creative, productive and happier."
      },
      "jira": {
        "basic-info": "JIRA is an issue tracking tool primarily built for agile software teams to plan, track, and release great software."
      },
      "ms-teams": {
        "basic-info": "Microsoft Teams is the ultimate messaging app for your organization.",
        "how-to-setup": {
          "connect-account": "1. Connect HappyFox Workflows to your Microsoft Teams Account.",
          "install-bot-to-teams": "3. Add HappyFox Workflows Bot to the different teams as required.",
          "login-teams": "2. Login to Microsoft Teams."
        }
      },
      "okta": {
        "basic-info": "Okta Single Sign On allows you to centralize user and access management for all your apps.",
        "how-to-setup": {
          "enter-credentials": "3. In HappyFox Workflows, enter your Okta URL as well as the API token you copied as part of the previous step.",
          "get-api-token": "1. Login as an Okta Admin, select Security > API and click on Create Token",
          "save-and-activate": "4. Save and activate the integration",
          "set-token-name": "2. Set the Token name as HappyFox Workflows and copy the API token to your clipboard. Click \"Ok got it\""
        }
      },
      "pipedrive": {
        "basic-info": "Pipedrive is a web-based Sales CRM and pipeline management solution that enables businesses to plan their sales activities and monitor deals. Using HappyFox Workflows, bring powerful automation to Pipedrive entities like Deals, Persons, etc., reduce manual effort and transform your business.",
        "how-to-setup": {
          "click-allow-and-install": "4. 'Click \"Allow and Install\". You will be redirected back to HappyFox Workflows.'",
          "click-authorize": "1. Click \"Authorize Pipedrive\" button below.",
          "login": "2. Log in to your Pipedrive account as an administrator.",
          "success-message": "5. Voila! Your HappyFox Workflows - Pipedrive integration is now active.",
          "verify-permissions": "3. Verify the permissions that HappyFox Workflows requests for your Pipedrive account."
        }
      },
      "salesforce": {
        "basic-info": "Salesforce is the industry’s leading cloud-based CRM software.",
        "how-to-setup": {
          "create-connected-app": "1. Open your salesforce application and create a connected app.",
          "ensure-appropriate-permissions": "3. Ensure that the application has appropriate permissions.",
          "enter-credentials": "5. In HappyFox Workflows, navigate back to salesforce app’s manage screen and enter the \"Consumer Key\" and \"Consumer Secret\" from above.",
          "find-consumer-key-consumer-secret": "4. Find the \"Consumer Key\" and \"Consumer Secret\" from the connected app.",
          "save-and-activate": "6. Save and activate the integration.",
          "use-oauth": "2. Use OAuth for authentication using the HappyFox callback URL as given below."
        },
        "pre-requisites": {
          "content": "Rest API needs to be enabled for the salesforce account.",
          "heading": "Pre-Requisites"
        }
      },
      "shopify": {
        "basic-info": "Shopify is a customizable E-Commerce platform designed to help retailers manage and their products in online stores and via social media.",
        "how-to-setup": {
          "create-connected-app": "1. Open your shopify application and create a custom app.",
          "enter-credentials": "4. In HappyFox Workflows, navigate back to shopify app's manage screen and enter the \"API Key\" and \"API Secret Key\" from above.",
          "find-consumer-key-consumer-secret": "3. Find the \"API Key\" and \"API Secret Key\" from the custom app.",
          "save-and-activate": "5. Save and activate the integration.",
          "use-oauth": "2. Use OAuth for authentication using the HappyFox callback URL as given below."
        }
      },
      "zendesk": {
        "basic-info": "Zendesk Support is a beautifully simple system for tracking, prioritizing, and solving customer support tickets.",
        "how-to-setup": {
          "api-token": "2. Go to Settings(gear icon) -> Channels -> API and generate a new API token",
          "login": "1. Login in to your zendesk instance.",
          "use-token": "3. Fill in details of the below form and click on save."
        }
      }
    },
    "apply": "Apply",
    "apply_macro_to_ticket": "Apply macro to a ticket",
    "apps": {
      "account-{app}": "{app} account",
      "add-another-azure-ad-tenant": "Add Another Azure Active Directory Tenant",
      "add-another-google-account": "Add another Google Account",
      "add-google-account": "Add Google Account",
      "add-tenant": "Add Azure Active Directory Tenant",
      "callback-url": "Callback URL",
      "clone-workflow-error-message": "We're experiencing some technical issues. Please check back later, and we appreciate your patience.",
      "clone-workflow-success-message": "Workflow cloned successfully",
      "connected-azure-ad-tenants": "Connected Azure Active Directory Tenants",
      "connected-bamboohr-account": "Connected Bamboo HR Account",
      "connected-google-accounts": "Connected Google Accounts",
      "connected-hf-chatbot-account": "Connected HappyFox Chatbot Account",
      "connected-ms-teams-account": "Connected MS Teams Account",
      "copy-url": "Copy url",
      "form-fields": {
        "access-token": "Access Token",
        "account-url": "Account URL",
        "admin-email": "Admin Email",
        "api-key": "API Key",
        "api-token": "API Token",
        "auth-token": "Auth Token",
        "bamboohr": {
          "disable-helper-text": "Disabling webhooks will stop executing existing workflows with BambooHR trigger and new workflows cannot be created with BambooHR trigger",
          "enable-helper-text": "Enable webhooks to allow triggers for BambooHR"
        },
        "client-id": "Client ID",
        "client-secret": "Client Secret",
        "consumer-key": "Consumer Key",
        "consumer-secret": "Consumer Secret",
        "email-address": "Email Address",
        "enable-webhook": "Enable webhooks",
        "jira-server-url": "JIRA Server URL",
        "okta": {
          "disable-helper-text": "Disabling webhooks will stop executing existing workflows with Okta trigger and new workflows cannot be created with Okta trigger",
          "enable-helper-text": "Enable webhooks to allow triggers for Okta"
        },
        "org-name": "Organization Name",
        "password": "Password",
        "public-key": "Public Key",
        "sandbox-account": "Sandbox Account",
        "secret-key": "Secret Key",
        "self-hosted": "Self Hosted ?",
        "shop-name": "Shop Name",
        "sub-domain": "Sub domain",
        "tenant": "Tenant ID",
        "username": "Username"
      },
      "generate-token": "Generate Token",
      "generating-token": "Generating Token...",
      "happyfox": {
        "account-url-placeholder": "Example: https://example.happyfox.com/"
      },
      "happyfox-service-desk": {
        "account-url-placeholder": "Example: https://example.happyfox.com/"
      },
      "how-to-setup": "How to setup",
      "logged-in-user-info": "Logged in user info",
      "salesforce": {
        "add-objects-help-text": "Click to add standard or custom objects",
        "additional-objects": "Additional Objects",
        "default-objects": "Default Objects",
        "select-objects": "Select Salesforce Objects to be used in HappyFox Workflows",
        "select-objects-description": "Choose standard or custom objects from salesforce to be displayed in HappyFox Workflow Actions other than the default objects."
      },
      "save-settings-error-message": "Please try again. If the problem persists, please contact support.",
      "save-settings-success-message": "Settings saved successfully",
      "syncing-data-message": "Syncing data from your account. Please wait, and refresh the page after some time to see the updated information.",
      "workflow-description-success-message": "Workflow Description saved successfully",
      "zendesk": {
        "workflow-app-setup": "HappyFox Workflows app setup for Zendesk",
        "workflow-app-setup-info": "This token should be used for setting up HappyFox Workflows app from Zendesk Marketplace"
      }
    },
    "apps-caps": "Apps",
    "apps-full-caps": "APPS",
    "asana": {
      "add-custom-fields": "Add Custom Field",
      "authorize": "Authorize Asana",
      "connect-account": "Connect your Asana Account",
      "custom-fields": "Custom Fields",
      "error": {
        "access_denied": "Please allow access to your Asana account"
      },
      "reauthorize-message": "Unable to connect to Asana. Please re-authorize",
      "remove-custom-fields": "Remove Custom Field",
      "title": "Asana",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure Asana app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Asana. Please try again after sometime",
      "unlink-failure-message-project": "We are unable to unregister webhook for "
    },
    "asset-custom-fields": "Asset Field",
    "asset-type": "Asset Type",
    "assign_app_to_user": "Assign an App to a User",
    "assignee": "Assignee",
    "attempts-remaining-{count}": "<span class=\"hf-font-semi-bold\">Attempts remaining:</span> {count}",
    "auto_categorize_ticket": "Auto suggest category for ticket",
    "auto_categorize_ticket-helper-text": "Choose a Ticket ID that needs to be processed to generate suggestions to categorize the ticket.",
    "auto_tag_ticket": "Auto suggest tags for ticket",
    "auto_tag_ticket-helper-text": "Choose a Ticket ID that needs to be processed to generate suggestions to tags for the ticket.",
    "auto_tagging_and_categorization": "Auto suggest tags and categories for text",
    "auto_tagging_and_categorization-helper-text": "Choose a field that contains the text to be processed to generate suggestions to tag and categorize the text.",
    "azure-active-directory": {
      "error": {
        "access_denied": "Please allow access to your Azure Active Directory account.",
        "admin_not_consented": "Please request the admin of your account to perform this action.",
        "duplicate_tenant": "Tenant has already been synced.",
        "invalid_account": "Invalid Account."
      },
      "reautorize-message": "Unable to connect to Azure Active Directory. Please re-authorize",
      "title": "Azure Active Directory",
      "unlink-confirmation-message": "Unlink will clear this tenant\"s access. You will have to configure this tenant again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Azure Active Directory. Please try again after sometime."
    },
    "azure-devops": {
      "error": {
        "access_denied": "Please allow access to your Azure Devops account"
      },
      "title": "Azure Devops",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure Azure Devops app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Azure Devops. Please try again after sometime"
    },
    "back-to-workflows-history": "Back To Workflows History",
    "bamboohr": {
      "title": "Bamboo HR"
    },
    "bamboohr-account": {
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure BambooHR app again",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink BambooHR Account. Please try again after sometime."
    },
    "basic-info": "Basic Info",
    "benefits": "Benefits",
    "benefits-caps": "BENEFITS",
    "between": "between",
    "billing": {
      "error-redirecting-message": "Unable to redirect to billing portal. Please try again later.",
      "redirecting": "Redirecting to billing portal..."
    },
    "cancel": "Cancel",
    "cancel_order": "Cancel Order",
    "category": "Category",
    "change": "Change",
    "change-email": "Change Email",
    "change-password": "Change Password",
    "change_contact": "Change Contact",
    "check-conditions-message": "Execute this group when the below conditions match",
    "check_conditions": "Check Conditions",
    "choose-action": "Choose an action",
    "choose-action-body-text": "Choose an action to continue building your workflow",
    "choose-actions-to-perform-on-conditions-match": "Choose actions to perform when specified conditions match",
    "choose-trigger": "Choose a trigger",
    "choose-trigger-body-text": "Choose a trigger to start building your workflow, A trigger is an event that starts your Workflow",
    "clear": "Clear",
    "clear_due_date": "Clear Due Date",
    "clear_sessions_user": "Clear all open sessions for a User",
    "click-here": "click here",
    "close": "Close",
    "close_order": "Close Order",
    "complete-registration": "Complete Registration",
    "completed": "Completed",
    "completing-registration": "Completing Registration...",
    "conditions": "Conditions",
    "confirm": "Confirm",
    "confirm-new-password": "Confirm New Password",
    "confirm-password": "Confirm Password",
    "contact-caps": "Contact",
    "contact-custom-fields": "Contact Custom Fields",
    "contact-phone-number": "Contact Phone Number",
    "contact-us": "Contact us",
    "contact_filter": "Contact Filter",
    "copy": "Copy",
    "copy_assets_from_ticket": "Copy Assets From Ticket",
    "cost-per-hour": "Enter cost per hour",
    "cost-saved": "Cost Saved",
    "create-condition": "Create Condition",
    "create-form": "Create Form",
    "create-new-condition": "Create New Condition",
    "create-workflow": "Create Workflow",
    "create_account": "Create Account",
    "create_associations": "Create Associations",
    "create_contact": "Create Contact",
    "create_contact_group": "Create Contact Group",
    "create_employee": "Create Employee",
    "create_incident": "Create Incident",
    "create_issue": "Create Issue",
    "create_lead": "Create Lead",
    "create_opportunity": "Create Opportunity",
    "create_order": "Create Order",
    "create_organization": "Create Organization",
    "create_related_ticket": "Create Related Ticket",
    "create_related_ticket_with_ticket_template": "Create Related Ticket With Ticket Template",
    "create_row": "Create Row with Given Column Values",
    "create_salesforce_object": "Create Salesforce Object",
    "create_service_request": "Create Service Request",
    "create_task": "Create Task",
    "create_task_with_task_template": "Create Task With Task Template",
    "create_ticket": "Create Ticket",
    "create_ticket_with_ticket_template": "Create Ticket With Ticket Template",
    "create_user": "Create User",
    "create_work_item": "Create Work Item",
    "created": "Created",
    "current-password": "Current Password",
    "dashboard-caps": "Dashboard",
    "data-in": "Data In",
    "data-out": "Data Out",
    "date_formatter": "Date/Time Formatter",
    "deactivate_user": "Deactivate a User",
    "deactivated": "Deactivated",
    "delete": "Delete",
    "delete-workflow-action-confirmation-message": "Are you sure you want to delete this action ?",
    "delete-workflow-confirmation-message": "Are you sure you want to delete this workflow ?",
    "delete-workflow-trigger-confirmation-message": "Are you sure you want to delete this trigger ?",
    "delete_row": "Delete Row",
    "delete_user": "Delete User",
    "deleting": "Deleting...",
    "depends-on": "Depends On",
    "description": "Description",
    "disable_user": "Disable User",
    "domains": "Domains",
    "domains-caps": "DOMAINS",
    "due-date": "Due Date",
    "duration": "Duration",
    "edit": "Edit",
    "edit_message": "Edit Message",
    "email": "Email",
    "email-placeholder": "Enter your email address here",
    "email-reset-password-success": "We have e-mailed your password reset link!",
    "email-verification": "Email Verification",
    "end-date": "End Date",
    "end_conversation": "End Conversation",
    "enterprise": "Enterprise",
    "erase_computer": "Erase Computer",
    "erase_mobile_device": "Erase Mobile Device",
    "event": "Event",
    "event-data": "Event Data",
    "event-data-filter-helptext": "Filter workflow execution by a particular field in the Event Data. Event data is the webhook payload with which the workflow was triggered.",
    "expand_text": "Expand Text",
    "expand_text-helper-text": "Choose a field that contains the text to be expanded.",
    "expire_password_user": "Expire the password for a User",
    "export": "Export",
    "exporting": "Exporting...",
    "extract_values_from_text": "Extract values from Text",
    "failed": "Failed",
    "field": "Field",
    "fields-drop-zone-instruction": "Drag fields from the left and drop here",
    "filter": "Filter",
    "filter-by": "Filter By",
    "filter_assets": "Filter Assets",
    "filters": {
      "add-condition": "Add Condition",
      "agent-scripts": {
        "in": "is",
        "not_in": "is not",
        "not_set": "is not used",
        "set": "is used"
      },
      "and": "and",
      "apply-filter": "Apply Filter",
      "applying-filter": "Applying Filter...",
      "assets": {
        "in": "contain",
        "not_set": "is empty",
        "set": "is not empty"
      },
      "between": "between",
      "by": "by",
      "clear": "Clear Filters",
      "condition-limit-reached-message": "Filter conditions limit of {limit} has been reached.",
      "conditions-count": "{conditionsCount, plural, =1 {1 condition applied} other {# conditions applied}}",
      "conditions-limit-exceed-warning-message": "Adding this saved filter will exceed the filter condition limit of {limit}.",
      "contains": "contains",
      "contains_case_insensitive": "contains (case insensitive)",
      "create-new-filter": "Create New Filter",
      "date": {
        "between": "between",
        "equals": "on",
        "gone_past_by": "is gone past by",
        "gt": "after",
        "gte": "on or after",
        "in_days": "in",
        "lt": "before",
        "lte": "on or before",
        "not_equals": "not on",
        "not_set": "is not set",
        "on": "on",
        "set": "is set"
      },
      "delete-confirmation-message": "Are you sure you want to delete the filter?",
      "delete-failure-message": "Something went wrong while deleting the filter. Please try again.",
      "delete-success-message": "Filter deleted successfully",
      "does_not_contain": "does not contain",
      "does_not_contain_case_insensitive": "does not contain (case insensitive)",
      "edit-condition": "Edit Condition",
      "edit-failure-message-on-deletion": "Filter cannot be edited because it has been deleted",
      "equal_to": "is equal to",
      "equals": "is",
      "for": "for",
      "gone_past_by": "is gone past by",
      "gt": "is greater than",
      "gte": "is greater than or equal to",
      "in": "is",
      "in_case_insensitive": "is (case insensitive)",
      "in_days": "in",
      "lt": "is less than",
      "lte": "is less than or equal to",
      "match-all": "Match All",
      "match-any": "Match Any",
      "new-filter": "New Filter",
      "no-filter-conditions-in-read-only": "No filter conditions applied in this Queue.",
      "not_by": "not by",
      "not_equals": "is not",
      "not_in": "is not",
      "not_in_case_insensitive": "is not (case insensitive)",
      "not_set": "is not set",
      "on": "on",
      "range": "is between",
      "reset": "Reset Filters",
      "save-failure-message": "Something went wrong while saving the filter. Please try again.",
      "save-success-message": "Filter saved successfully",
      "set": "is set",
      "tags": {
        "contains": "contains",
        "does_not_contain": "does not contain",
        "equals": "is",
        "in": "contain",
        "not_equals": "is not",
        "not_in": "does not contain",
        "not_set": "is empty",
        "set": "is not empty"
      }
    },
    "first-name": "First Name",
    "forgot-password": "Forgot Password?",
    "form-fields": "Form Fields",
    "forms": {
      "create-new-form": "Create New Form",
      "edit-form": "Edit this form",
      "no-forms-content": "No forms created yet",
      "no-forms-header": "No Forms",
      "save-failure-message": "Something went wrong while saving the form settings",
      "save-success-message": "Form settings has been saved successfully",
      "title": "Forms",
      "title-caps": "FORMS"
    },
    "forward_ticket": "Forward Ticket",
    "get_asset_info": "Get Asset Info",
    "get_assets_for_contact": "Get Assets for Contact",
    "get_company": "Get Company",
    "get_computer_diagnostic": "Get Diagnostic information for Computer",
    "get_contact": "Get Contact",
    "get_contact_group_info": "Get Contact Group Info",
    "get_custom_object": "Get Custom Object",
    "get_deal": "Get Deal",
    "get_employee_details": "Get Employee details",
    "get_or_create_user": "Get Or Create User",
    "get_salesforce_object": "Get Object by Id",
    "get_task": "Get Task Details",
    "get_ticket": "Get Ticket",
    "get_user": "Get User",
    "get_user_by_login_name": "Get User properties",
    "get_user_details": "Get user details",
    "get_user_info": "Get User Info",
    "go-to-login": "Go To Login",
    "google-account": {
      "error": {
        "access_denied": "Please allow access to your Google account",
        "insufficient_access": "Please grant all required permissions in the Google consent screen to link your account",
        "invalid_login": "There is a problem linking your account. Please try again.",
        "unknown": "Could not link your Google account. Please contact support."
      },
      "unlink-confirmation-message": "Unlinking this account will result in workflows that are using spreadsheets from this account failing.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Google Account. Please try again after sometime."
    },
    "google-sheets": {
      "authorize": "Authorize Google Sheets",
      "authorize-another-account": "Authorize Google Sheets of another account",
      "title": "Google Sheets"
    },
    "happyfox": {
      "error": {
        "access_denied": "Please allow access to your HappyFox account",
        "invalid_request": "Invalid Request"
      },
      "title": "HappyFox Helpdesk"
    },
    "happyfox-chatbot": {
      "title": "HappyFox Chatbot"
    },
    "happyfox-service-desk": {
      "error": {
        "access_denied": "Please allow access to your HappyFox Service Desk account",
        "invalid_request": "Invalid Request"
      },
      "title": "HappyFox Service Desk"
    },
    "happyfox-workflows": "HappyFox Workflows",
    "has-attachments": "Has Attachments",
    "http_request": "Make HTTP Request",
    "hubspot": {
      "authorize": "Authorize HubSpot",
      "connect-account": "Connect your HubSpot Account",
      "error": {
        "access_denied": "Please allow access to your HubSpot account"
      },
      "hub-portal-text": "HubSpot Hub:",
      "reauthorize-message": "Unable to connect to HubSpot. Please re-authorize",
      "title": "HubSpot",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure HubSpot app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink HubSpot. Please try again after sometime"
    },
    "image-url": "Image URL",
    "in_progress": "In Progress",
    "incident_created": "Incident Created",
    "incident_updated": "Incident Updated",
    "inline_lookup_table": "Use Lookup Table",
    "insert": "Insert",
    "insert-image": "Insert Image",
    "insert-tags": "Insert Merge Fields",
    "instant-trigger": "Instant Trigger",
    "instant-trigger-information": "This is an instant trigger. To know more about instant triggers",
    "invalid-login-attempts-warning-message": "<span class=\"hf-font-semi-bold\">Warning</span>: After 5 consecutive unsuccessful login attempts, your account will be locked.",
    "invite-agent": "Invite Agent",
    "jamf-pro": {
      "title": "JAMF Pro"
    },
    "jira": {
      "title": "Jira"
    },
    "just-now": "Just Now",
    "last-contact-reply": "Last Contact Reply",
    "last-modified-at": "Last Modified At",
    "last-name": "Last Name",
    "last-staff-reply": "Last Staff Reply",
    "last-updated-at": "Last Updated At",
    "library-caps": "Library",
    "link": "link",
    "link_asana_task": "Link Asana Task To A Ticket",
    "link_assets": "Link Assets To Ticket",
    "link_assets_to_contact": "Link Assets to Contact",
    "link_azure_devops_work_item": "Link Azure DevOps Work Item To A Ticket",
    "link_jira_issue": "Link JIRA Issue To A Ticket",
    "lock_computer": "Lock Computer",
    "lock_mobile_device": "Lock Mobile Device",
    "logging-in": "Logging in...",
    "login": "Login",
    "login-error": "Invalid Email or Password",
    "login-happyfox-workflows": "Login to HappyFox Workflows",
    "logout": "Logout",
    "lookup_row": "Lookup Row from Google Sheet",
    "lookup_row_multiple_columns": "Lookup Row from Google Sheet using Multiple Columns",
    "lose-changes-warning": "Are you sure you want to leave this page? Any unsaved changes will be lost.",
    "lose-reorder-changes-warning": "Are you sure you want to leave this page? You have some unsaved changes. Any unsaved changes will be lost.",
    "manage": {
      "agent-update-success-message": "Agent information updated successfully",
      "cancel-invite-success-message": "Pending Agent Deleted successfully.",
      "deactivate": "Deactivate",
      "deactivate-agent-confirmation-message": "Are you sure you want to deactivate this agent?",
      "deactivate-agent-success-message": "Agent deactivated successfully",
      "deactivating": "Deactivating...",
      "delete-agent-invitation-confirmation-message": "Are you sure you want to delete this pending agent invitation?",
      "delete-invitation": "Delete Invitation",
      "new-agent-activation-email-sent": "Activation email sent to agent",
      "reactivate": "Re-activate",
      "reactivate-agent-confirmation-message": "Are you sure you want to re-activate this agent?",
      "reactivate-agent-success-message": "Agent re-activated successfully",
      "reactivating": "Re-activating...",
      "reinvite": "Reinvite",
      "reinvite-agent": "Reinvite Agent",
      "reinvite-agent-confirmation-message": "Are you sure you want to resend the invitation to",
      "resend-invite-success-message": "Invitation resent successfully.",
      "title": "Manage",
      "unlock": "Unlock",
      "unlock-agent-confirmation-message": "Are you sure you want to unlock this agent?",
      "unlock-success-message": "Agent unlocked successfully",
      "unlocking": "Unlocking"
    },
    "manage-caps": "MANAGE",
    "math_operations": "Perform Math Operations",
    "merge_contact": "Merge Contact",
    "merge_ticket": "Merge Ticket",
    "merge_tickets": "Merge Tickets",
    "message": "Message",
    "module-switcher": {
      "bi-name": "BI",
      "chat-name": "Chat",
      "chatbot-name": "Chatbot",
      "helpdesk-name": "Help Desk",
      "in-app-chat-name": "In-App Chat",
      "more-from-happyfox": "More from",
      "workflows-name": "Workflows"
    },
    "month-to-date": "Month To Date",
    "ms-teams": {
      "authorize": "Authorize MS Teams",
      "connect-account": "Connect with your MS Teams Account",
      "error": {
        "consent_required": "Please allow access to your Microsoft account",
        "invalid_access": "Could not link to your Microsoft account. Please contact support",
        "invalid_grant": "Please grant all required permissions in the Microsoft consent screen to link your account",
        "unauthorized_access": "You are not authorized to access this app"
      },
      "title": "MS Teams",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure MS Teams app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink MS Teams Account. Please try again after sometime."
    },
    "my-settings": "My Settings",
    "name": "Name",
    "new-password": "New Password",
    "new_employee_started": "New Employee Started",
    "new_time_off_request_created": "New Time Off Request Created",
    "next_seven_days": "in next 7 days",
    "no": "No",
    "no-account": "Don't have an account?",
    "no-of-action-executions": "# of Actions Executed",
    "no-of-messages": "No. of messages",
    "no-of-workflow-runs": "# of Workflow Runs",
    "no-results-found": "No results found",
    "no-token-register": "Invalid registration token",
    "no-token-reset-password": "Invalid reset password token",
    "okta": {
      "title": "Okta"
    },
    "on": "on",
    "one-day-ago": "a day ago",
    "or": "or",
    "otp": "OTP",
    "otp-placeholder": "Enter your OTP",
    "password": "Password",
    "password-placeholder": "Enter your password",
    "password-policy": {
      "heading": "Password must contain",
      "maximum-character": "Maximum of 128 characters",
      "minimum-character": "Minimum of 8 characters",
      "one-lowercase": "At least one lowercase letter",
      "one-number": "At least one number",
      "one-special-character": "At least one special character",
      "one-uppercase": "At least one upper case letter"
    },
    "passwords-mismatch": "Passwords do not match",
    "pending": "Pending",
    "pipedrive": {
      "authorize": "Authorize Pipedrive",
      "connect-account": "Connect with your Pipedrive Admin Account",
      "reautorize-message": "Unable to connect to Pipedrive. Please re-authorize",
      "title": "Pipedrive",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure Pipedrive app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Pipedrive. Please try again after sometime"
    },
    "premium": "Premium",
    "preview": "Preview:",
    "preview-action-text": "This action is available only in preview mode.",
    "priority": "Priority",
    "private": "Private",
    "process_refund": "Process Refund",
    "products": "Products",
    "products-caps": "PRODUCTS",
    "profile": {
      "change-email-success-message": "A verification email is sent to your new email. Please verify.",
      "change-password-success-message": "Password changed successfully",
      "save-failure-message": "Something went wrong while saving the form settings",
      "save-success-message": "Profile has been updated successfully"
    },
    "public": "Public",
    "queued": "Queued",
    "quick_reply": "Quick Reply",
    "reactivate_user": "Reactivate a User",
    "reauthorize": "Reauthorize",
    "redact_text": "Redact text",
    "redact_text-helper-text": "Choose a field that contains the text to be redacted.",
    "register": "Register",
    "register-for-happyfox-workflows": "Register for HappyFox Workflows",
    "registration-success": "Registration Success",
    "relate_tickets": "Relate Tickets",
    "relative-time-unit": {
      "hours-minutes-ago": "{hrs, plural, =1 {1 hr} other {# hrs}} {mins, plural, =0 {ago} =1 {1 min ago} other {# mins ago}}",
      "minutes-ago": "{value, plural, =1 {1 minute} other {# minutes}} ago",
      "seconds-ago": "{value, plural, =1 {1 second} other {# seconds}} ago",
      "years-ago": "{value, plural, =1 {1 year} other {# years}} ago"
    },
    "remove_associations": "Remove Associations",
    "remove_computer_from_computer_group": "Remove Computer from Computer Group",
    "remove_member_from_group": "Remove Member From Group",
    "remove_tags": "Remove Tags",
    "remove_user_from_group": "Remove a User from a Group",
    "remove_user_from_user_group": "Remove User from User Group",
    "reopen_order": "Reopen Order",
    "rephrase_text": "Rephrase text in the given format",
    "rephrase_text-concise": "Concise",
    "rephrase_text-formal": "Formal",
    "rephrase_text-helper-text": "Choose a field that contains the text to be rephrased.",
    "rephrase_text-informal": "Informal",
    "request_approval": "Request Approval",
    "resend": "Resend",
    "resend-otp": "Resend OTP",
    "resend-verification": "Resend Verification",
    "resend-verification-in-progress": "Resending...",
    "resending": "Resending...",
    "resending-otp": "Resending OTP...",
    "reset": "Reset",
    "reset-password": "Reset Password",
    "reset-password-success": "Password has been reset successfully",
    "reset_factors_user": "Reset the Login Factors for a User",
    "reset_password_user": "Reset password for a User",
    "revoke_app_for_user": "Revoke access to an App for a User",
    "salesforce": {
      "title": "Salesforce"
    },
    "save": "Save",
    "save-workflow-error-message": "Something went wrong. Please try again.",
    "saving": "Saving...",
    "schedule_os_update_computer": "Schedule OS Update for Computer",
    "schedule_os_update_mobile_device": "Schedule OS Update for Mobile Device",
    "scheduled-trigger": "Scheduled Trigger",
    "scheduled-trigger-information": "This a scheduled trigger. To know more about scheduled triggers",
    "search_account": "Search Account",
    "search_contact": "Search Contact",
    "search_lead": "Search Lead",
    "search_opportunity": "Search Opportunity",
    "search_order": "Search Order",
    "search_order_by_id": "Search Order By ID",
    "search_salesforce_object": "Search Salesforce Object (Find One)",
    "search_salesforce_objects": "Search Salesforce Objects (Find All)",
    "search_tickets": "Search Tickets",
    "search_users": "Search Users",
    "send_blank_push_computer": "Send a blank push notification to Computer",
    "send_blank_push_mobile_device": "Send a blank push notification to Mobile Device",
    "send_channel_message": "Send Channel Message",
    "send_direct_message": "Send Direct Message",
    "send_email": "Send Email",
    "send_message": "Send Message",
    "separate-options-comma": "Separate multiple options with comma",
    "service_request_created": "Service Request Created",
    "service_request_updated": "Service Request Updated",
    "set_due_date": "Set Due Date",
    "shopify": {
      "title": "Shopify"
    },
    "show-masked-data": "Show Masked Data",
    "sign-in-with-google": "Sign in with Google",
    "sla-breached": "SLA breached",
    "slack": {
      "authorize": "Authorize Slack",
      "connect-account": "Connect your Slack Account",
      "error": {
        "access_denied": "Please allow access to your Slack account"
      },
      "reautorize-message": "Unable to connect to Slack. Please re-authorize",
      "title": "Slack",
      "unlink-confirmation-message": "Unlink will clear your existing setup. You will have to configure Slack app again.",
      "unlink-failure-generic-message": "Something went wrong while trying to unlink Slack. Please try again after sometime"
    },
    "start-date": "Start Date",
    "start-search-help-text": "No match found",
    "status": "Status",
    "status-behavior": "Status Behavior",
    "step-{index}": "Step {index}",
    "steps-needs-attention-{count}": "{count} step(s) need your attention",
    "sub_task_created": "Sub Task Created",
    "subject": "Subject",
    "submit": "Submit",
    "submitting": "Submitting...",
    "subscribed": "Subscribed",
    "success-message-save-test-action": "Action saved and tested successfully",
    "success-message-save-test-trigger": "Trigger saved and tested successfully",
    "summarize_text": "Summarize Text",
    "summarize_text-helper-text": "Choose a field that contains the text to be summarized.",
    "suspend_user": "Suspend a User",
    "tags": "Tags",
    "tags-caps": "TAGS",
    "task_created": "Task Created",
    "task_updated": "Task Updated",
    "ticket-custom-fields": "Ticket Custom Fields",
    "ticket-id": "Ticket ID",
    "ticket-source": "Ticket Source",
    "ticket-visibility": "Ticket Visibility",
    "ticket_created": "Ticket Created",
    "ticket_updated": "Ticket Updated",
    "time-saved": "Time Saved (hours)",
    "time_off_request_status_update": "Time Off Request Status Update",
    "today": "Today",
    "tomorrow": "tomorrow",
    "tomorrow-caps": "Tomorrow",
    "transform_text": "Transform Text",
    "trigger_satisfaction_survey": "Trigger Satisfaction Survey",
    "triggers": {
      "apply-conditions-message": "Workflow will be triggered only when the following conditions are met",
      "apply-conditions-optional": "Add condition(s) for Trigger(Optional)",
      "cancel": "Cancel",
      "catch-new-trigger": "Catch New Trigger",
      "condition-failed-message": "Applied conditions have failed. Are you sure you want to proceed without revisiting them ?",
      "continue": "Continue",
      "disable-trigger-message-asana": "Asana triggers is disabled for this account. Enable triggers in Manage App for Asana to continue with this workflow",
      "disable-trigger-message-bamboohr": "BambooHR triggers is disabled for this account. Enable webhooks in Manage App for BambooHR to continue with this workflow",
      "disable-trigger-message-okta": "Okta triggers is disabled for this account. Enable webhooks in Manage App to continue with this workflow",
      "exit-and-go-back": "Exit and Go Back",
      "incident_created": {
        "information-available": "Incident Information Received",
        "run-event-trigger": "This trigger is activated when a new Incident is created in HappyFox Service Desk. To test this trigger, please go to your HappyFox Service Desk account and create a sample Incident. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of incidents created. You can select one from the list to get started.",
        "selected-trigger": "Selected Incident Information"
      },
      "incident_updated": {
        "information-available": "Incident Information Received",
        "run-event-trigger": "This trigger is activated when an Incident is updated in HappyFox Service Desk. To test this trigger, please go to your HappyFox Service Desk account and update an Incident. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of incidents updated. You can select one from the list to get started.",
        "selected-trigger": "Selected Incident Information"
      },
      "information-available": "Ticket Created Information Available",
      "new_employee_started": {
        "information-available": "Employee Information Received",
        "run-event-trigger": "This trigger is activated when an employee's start date is set to today in BambooHR. To test this trigger, please go to your BambooHR account and update an employee's start date to today. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of new employee who have started. You can select one from the list to get started.",
        "selected-trigger": "Selected Employee Information"
      },
      "new_time_off_request_created": {
        "information-available": "Time Off Information Received",
        "run-event-trigger": "This trigger is activated when an employee has created a new time off request. To test this trigger, please go to your BambooHR account and create a new time off request. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of new time off request created. You can select one from the list to get started.",
        "selected-trigger": "Selected Time Off Information"
      },
      "no-results-found": "No results found",
      "no-trigger-received": "No trigger information received. Please",
      "not-received-trigger": "We have not received the updated trigger data yet.",
      "only-select-trigger-available": "No trigger available other than the selected trigger.",
      "proceed": "Proceed",
      "proceeding": "Proceeding",
      "refresh": "Refresh",
      "refreshing": "Refreshing...",
      "review-trigger-message": "Please review the trigger details. You can optionally add condition(s) to the trigger event. Go back to capture a different trigger details else click 'Proceed'",
      "saved-and-tested-message": "Trigger saved and tested successfully",
      "see-updated-trigger": "Click here if you are not seeing the updated trigger",
      "send-info-to-trigger": "Send trigger information",
      "service_request_created": {
        "information-available": "Service Request Information Received",
        "run-event-trigger": "This trigger is activated when a new Service Request is created in HappyFox Service Desk. To test this trigger, please go to your HappyFox Service Desk account and create a sample Service Request. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of service requests created. You can select one from the list to get started.",
        "selected-trigger": "Selected Service Request Information"
      },
      "service_request_updated": {
        "information-available": "Service Request Information Received",
        "run-event-trigger": "This trigger is activated when a Service Request is updated in HappyFox Service Desk. To test this trigger, please go to your HappyFox Service Desk account and update a Service Request. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of service requests updated. You can select one from the list to get started.",
        "selected-trigger": "Selected Service Request Information"
      },
      "sub_task_created": {
        "information-available": "Task Information Received",
        "run-event-trigger": "This trigger is activated when a sub task has been created in Asana. To test this trigger, please go to your Asana account and create a sub task. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of sub tasks which has been created. You can select one from the list to get started.",
        "selected-trigger": "Selected Task Information"
      },
      "task_created": {
        "information-available": "Task Information Received",
        "run-event-trigger": "This trigger is activated when a task has been created in Asana. To test this trigger, please go to your Asana account and create a task. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of tasks which has been created. You can select one from the list to get started.",
        "selected-trigger": "Selected Task Information"
      },
      "task_updated": {
        "information-available": "Task Information Received",
        "run-event-trigger": "This trigger is activated when a task has been updated in Asana. To test this trigger, please go to your Asana account and update a task. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of tasks which has been updated. You can select one from the list to get started.",
        "selected-trigger": "Selected Task Information"
      },
      "test-and-proceed": "Save & Test",
      "testing-and-proceeding": "Saving & Testing",
      "ticket_created": {
        "information-available": "Ticket Information Received",
        "run-event-trigger": "This trigger is activated when a new ticket is created in HappyFox Helpdesk. To test this trigger, please go to your HappyFox account and create a sample ticket. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of tickets created. You can select one from the list to get started.",
        "selected-trigger": "Selected Ticket Information"
      },
      "ticket_updated": {
        "information-available": "Ticket Information Received",
        "run-event-trigger": "This trigger is activated when a ticket is updated in HappyFox Helpdesk. To test this trigger, please go to your HappyFox account and update an existing ticket. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of tickets updated. You can select one from the list to get started.",
        "selected-trigger": "Selected Ticket Information"
      },
      "time_off_request_status_update": {
        "information-available": "Time Off Information Received",
        "run-event-trigger": "This trigger is activated when an employee has updated his/her time off request. To test this trigger, please go to your BambooHR account and update an employee's time off request. Then, wait for a few minutes and return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of time off request status updated. You can select one from the list to get started.",
        "selected-trigger": "Selected Time Off Information"
      },
      "trigger-details": "Trigger Details",
      "trigger-selected-check-condition-warning": "Are you certain about altering the trigger data? Please note that you may need to review the check condition(s) after making the change.",
      "try-again": "try again",
      "user_account_auto_locked": {
        "information-available": "User Account Information Received",
        "run-event-trigger": "This trigger is activated when a user is automatically locked out of his/her Okta account. To test this trigger, please attempt to lock a user in Okta. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of accounts auto locked. You can select one from the list to get started.",
        "selected-trigger": "Selected User Account Information"
      },
      "user_activated": {
        "information-available": "User Information Received",
        "run-event-trigger": "This trigger is activated when a user has been activated in Okta. To test this trigger, please go to your Okta account and activate a user's account. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of accounts activated. You can select one from the list to get started.",
        "selected-trigger": "Selected User Information"
      },
      "user_added_to_group": {
        "information-available": "User Information Received",
        "run-event-trigger": "This trigger is activated when a user is added to a group in Okta. To test this trigger, please go to your Okta account and add a user to a group. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of users added to group. You can select one from the list to get started.",
        "selected-trigger": "Selected User Information"
      },
      "user_deactivated": {
        "information-available": "User Information Received",
        "run-event-trigger": "This trigger is activated when a user has been deactivated in Okta. To test this trigger, please go to your Okta account and deactivate a user's account. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of accounts deactivated. You can select one from the list to get started.",
        "selected-trigger": "Selected User Information"
      },
      "user_profile_updated": {
        "information-available": "User Profile Information Received",
        "run-event-trigger": "This trigger is activated when a user's profile has been updated in Okta. To test this trigger, please go to your Okta account and update a user's profile. Then, you can return here to view the trigger information that was captured.",
        "select-trigger-list-helper": "Here is the list of profiles updated. You can select one from the list to get started.",
        "selected-trigger": "Selected User Profile Information"
      },
      "wait-receive-trigger": "Waiting to receive trigger information",
      "webhook": {
        "apply-conditions-optional": "Add condition(s) for Webhook(Optional)",
        "catch-new-trigger": "Catch New Webhook",
        "information-available": "Webhook Information Available",
        "no-trigger-received": "No webhook received. Please",
        "not-received-trigger": "We have not received the updated webhook yet.",
        "only-select-trigger-available": "No webhook available other than the selected webhook.",
        "review-trigger-message": "Please review the webhook details. You can optionally add condition(s) to the webhook event. Go back to capture a different webhook else click 'Proceed'",
        "run-event-trigger": "Run a test event and trigger the below webhook from an external app",
        "see-updated-trigger": "Click here if you are not seeing the updated webhook",
        "select-trigger-list-helper": "Here is the list of webhook details. You can select one from the list to get started.",
        "selected-trigger": "Selected Webhook Information",
        "send-info-to-trigger": "Send Info to webhook",
        "success-trigger-message": "Successfully received a sample webhook",
        "trigger-details": "Webhook Details",
        "wait-receive-trigger": "Waiting to receive webhook"
      },
      "webhook-url": "Webhook URL:"
    },
    "unknown-state-error": "Your subscription is yet to be activated. Please contact support or try again later.",
    "unlink": "Unlink",
    "unlink_asana_task": "Unlink Asana Task From A Ticket",
    "unlink_azure_devops_work_item": "Unlink Azure DevOps Work Item From A Ticket",
    "unlink_jira_issue": "Unlink JIRA Issue From A Ticket",
    "unlinking": "Unlinking...",
    "unlock_user": "Unlock a User",
    "unresponded": "Unresponded",
    "unsaved-action-{prefix}": "Action {prefix} is not saved. Please save the action before proceeding",
    "unsuspend_user": "Unsuspend a User",
    "update": "Update",
    "update_account": "Update Account",
    "update_activity": "Update Activity",
    "update_assignee": "Set Assignee",
    "update_associations": "Update Associations",
    "update_category": "Set Category",
    "update_contact": "Update Contact",
    "update_contact_custom_fields": "Set Contact Custom Fields",
    "update_contact_group": "Update Contact Group",
    "update_contact_group_available_time": "Update Contact Group Available Time",
    "update_deal": "Update Deal",
    "update_due_date": "Set Due Date",
    "update_employee": "Update Employee",
    "update_incident": "Update Incident",
    "update_issue": "Update Issue",
    "update_lead": "Update Lead",
    "update_opportunity": "Update Opportunity",
    "update_order": "Update Order",
    "update_organization": "Update Organization",
    "update_person": "Update Person",
    "update_priority": "Set Priority",
    "update_row": "Update Row with Given Column Values",
    "update_salesforce_object": "Update Salesforce Object",
    "update_service_request": "Update Service Request",
    "update_service_request_custom_fields": "Update Service Request Custom Fields",
    "update_status": "Set Status",
    "update_tags": "Set Tags",
    "update_task": "Update Task",
    "update_ticket": "Update Ticket",
    "update_ticket_custom_fields": "Set Ticket Custom Fields",
    "update_ticket_fields": "Set Ticket Fields",
    "update_time_spent": "Set Time Spent",
    "update_user": "Update User",
    "update_user_fields": "Set End User Fields",
    "use-workflow": "Use Workflow",
    "user_account_auto_locked": "User Account Auto-Locked",
    "user_activated": "User Activated",
    "user_added_to_group": "User Added to Group",
    "user_deactivated": "User Deactivated",
    "user_denied": "The request to authorize Pipedrive app to connect with HappyFox Workflows was cancelled by the user.",
    "user_profile_updated": "User Profile Updated",
    "username-email": "Email",
    "username-email-placeholder": "Enter your username/email address here",
    "validation": {
      "decimal-not-allowed": "Decimal values are not allowed.",
      "empty-phone-message": "Phone number cannot be empty.",
      "invalid-date-message": "Invalid Date",
      "invalid-decimal-limit": "Ensure that there are no more than 2 decimal places.",
      "invalid-email-message": "Invalid Email Address",
      "invalid-number-configuration": "Configuration error - Maximum value cannot be less than minimum value.",
      "invalid-number-message": "Not a valid number",
      "invalid-phone-message": "Invalid Phone Number",
      "invalid-slug-message": "Invalid Slug. Slug text can only include lower-case letters, numbers, and dashes.",
      "invalid-text-configuration": "Configuration error - Maximum character limit cannot be less than minimum character limit.",
      "invalid-url": "Please enter a valid URL.",
      "mandatory-error-message": "This field is required.",
      "number-{max}-value-limit": "Please use number lesser than or equal to {max}.",
      "number-{min}-value-limit": "Please use number greater than or equal to {min}.",
      "number-{min}-{max}-value-limit": "Please use number between {min} and {max}.",
      "select-date": "Please select a valid date",
      "select-time-in-future": "Please select a time in the future.",
      "text-{max}-characters-limit": "Please use maximum of {max} characters.",
      "text-{min}-characters-limit": "Please use minimum of {min} characters.",
      "text-{min}-{max}-characters-limit": "Please use between {min} and {max} characters."
    },
    "verification-link-success": "Verification link has been sent to your email.",
    "verify-otp": "Verify OTP",
    "verifying-otp": "Verifying OTP...",
    "view-less": "View Less...",
    "view-more": "View More...",
    "waiting": "Waiting",
    "webhhok": "Webhook",
    "webhook": "Catch Webhook Info",
    "webhook-info": "Webhook Info",
    "workflow": "Workflow",
    "workflow-actions-reached-{limit}": "You have reached the permissible limit of {limit} actions per workflow.",
    "workflows": {
      "active-workflows": "Active Workflows",
      "add-additional-azure-devops-fields": "Add additional Azure Devops Fields",
      "add-additional-jira-fields": "Add additional Jira fields",
      "add-asset": "Add Asset",
      "add-attendee": "Add Attendee",
      "add-choice": "Add Choice",
      "add-contact-custom-field": "Add Contact Custom Field",
      "add-extra-field": "Add Extra Field",
      "add-google-sheets-column-value": "Add Column Value",
      "add-google-sheets-lookup-column": "Add Lookup Column",
      "add-option": "Add Option",
      "add-participant": "Add Participant",
      "add-pattern": "Add Pattern",
      "add-phone": "Add Phone",
      "add-quick-reply-choice": "Add quick reply choice",
      "add-requestor-custom-field": "Add Requestor Custom Field",
      "add-ticket-custom-field": "Add Ticket Custom Field",
      "all-workflows": "All Workflows",
      "clone-workflow-error-message": "We're experiencing some technical issues. Please check back later, and we appreciate your patience.",
      "clone-workflow-success-message": "Workflow cloned successfully",
      "contact-custom-field": "Contact Custom Field #{index}",
      "dashboard": "Dashboard",
      "dashboard-caps": "DASHBOARD",
      "execution-timeout-error-message": "Workflow execution timed out.",
      "google-sheets-column-values-title": "Column Values",
      "google-sheets-lookup-column-title": "Lookup Columns",
      "history": "Workflows Execution History",
      "history-caps": "HISTORY",
      "history-export-failure-message": "An error occurred while exporting the workflow execution history. Please try again later.",
      "history-export-success-message": "An email with an attachment containing the export will be sent to you shortly.",
      "inactive-workflows": "Inactive Workflows",
      "library": "Workflows Library",
      "library-caps": "WORKFLOWS LIBRARY",
      "my-workflows": "My Workflows",
      "no-action-executions-found": "No Workflow Action Execution History found",
      "no-history-found": "No Workflow Execution History found",
      "no-library-found": "No Workflow Examples Found",
      "no-workflows-content": "No workflows created yet",
      "no-workflows-header": "No workflows",
      "remove-asset": "Remove Asset",
      "remove-attendee": "Remove attendee",
      "remove-choice": "Remove Choice",
      "remove-contact-custom-field": "Remove Contact Custom Field",
      "remove-extra-field": "Remove extra field",
      "remove-field": "Remove field",
      "remove-google-sheets-column-value": "Remove Column Value",
      "remove-google-sheets-lookup-column": "Remove Lookup Column",
      "remove-option": "Remove Option",
      "remove-participant": "Remove Participant",
      "remove-pattern": "Remove Pattern",
      "remove-phone": "Remove Phone",
      "remove-quick-reply-choice": "Remove quick reply choice",
      "remove-requestor-custom-field": "Remove Requestor Custom Field",
      "remove-ticket-custom-field": "Remove Ticket Custom Field",
      "run-history": "Run History",
      "run-history-caps": "RUN HISTORY",
      "ticket-custom-field": "Ticket Custom Field #{index}",
      "title": "Workflows",
      "title-caps": "WORKFLOWS",
      "workflow-description-success-message": "Workflow Description saved successfully",
      "wrong-page-content": "No workflows present in the current page",
      "wrong-page-header": "Wrong page"
    },
    "year-to-date": "Year To Date",
    "yes": "Yes",
    "yesterday": "yesterday",
    "zendesk": {
      "domains-help-text": "Enter domains separated by a space. For example, \"acme.com ajax.com\". Emails from these domains will be added to the organization.",
      "due-date-help-text": "Can be an absolute date(YYYY-MM-DD) or Relative date(n days from now)",
      "requester-help-text": "Provide end user email id",
      "search-tickets-help-text": "Search using a ticket id or search text",
      "source-comment-help-text": "Private comment to add to the source ticket",
      "source-ticket-ids-help-text": "Input multiple ticket ids by separating them with comma",
      "target-comment-help-text": "Private comment to add to the target ticket",
      "title": "Zendesk"
    }
  };
});